import { motion } from "framer-motion";
import Electric from "../images/electric.gif";
import Electrical from "../images/electrical.gif";
import PCBShowcase from "../images/PCBShowCase.gif";
import Electronics from "../images/electronics.gif";
import { FiChevronRight } from "react-icons/fi";

import "./Product.css";

const Product = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        ease: "easeInOut",
        duration: 0.5,
        delay: 0.1,
      }}
      className="container mx-auto py-5 px-2"
    >
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-7/10 p-2">
          <div className="text-xl font-bold mb-4">S.B Technologies</div>
          <p
            className="sm:text-lg lg:text-lg md:text-lg text-primary-dark w-full block lg:leading-8 lg:word-spacing-wider lg:word-spacing-wide"
            style={{ textAlign: "justify" }}
          >
            offers the latest technology and processes, combined
            withstate-of-the-art Manufacturing facility on a global scale. The
            Company specializes in medium to high volume, high mix/high
            complexity mixed technology (SMT and Through Hole) High Speed
            Automated assembly.
          </p>
          <div className="marquee-container">
            <div className="marquee-content">
              <strong>
                Mixed Technology PCB Assemblies, Wave Soldering, Reflow
                Soldering, Electronics Manufacturing Services, No-lead Package
                Assembly, 0201 Chip to 14 mm square Packages, Turnkey Projects,
                Automated SMD Assembly.
              </strong>
            </div>
            <div className="marquee-content">
              <strong>
                Mixed Technology PCB Assemblies, Wave Soldering, Reflow
                Soldering, Electronics Manufacturing Services, No-lead Package
                Assembly, 0201 Chip to 14 mm square Packages, Turnkey Projects,
                Automated SMD Assembly.
              </strong>
            </div>
          </div>
          <div className="text-lg font-semibold mb-4">
            Some of our Specializations in the field:
          </div>
          <hr className="border-2 border-gray-600 mb-5" />
          <div className="mt-4 mb-6">
            {[
              "Electrical Application",
              "Telecommunication",
              "Consumer Electronics",
              "Defence Application",
              "CNC Machine",
              "UPS",
              "Digital Weighing Scale",
            ].map((application) => (
              <div key={application} className="flex items-center mb-2">
                <FiChevronRight className="text-2xl font-bold text-color" />
                <p className="ml-2">{application}</p>
              </div>
            ))}
          </div>
          <a
            href="enquiry"
            className="text-lg font-semibold underline text-color"
          >
            Our Online Instant Quote for PCB Assembly
          </a>
          <p
            className="sm:text-lg lg:text-lg md:text-lg text-primary-dark w-full block lg:leading-8 lg:word-spacing-wider lg:word-spacing-wide mt-4"
            style={{ textAlign: "justify" }}
          >
            Will response and send you a quotation instantly upon your
            submission of the quote request through our web site.{" "}
            <span className="text-color">S.B.TECHNOLOGIES</span> experienced
            design engineers and customer service engineers are always here to
            provide enhanced customer service value and assist you for your
            different product and service needs. We cooperate with you on your
            designs and work out together with you on your engineering
            challenges. We are just a click away on the internet highway or one
            call away from your telephone and will be there together with you
            whenever you need.
          </p>
        </div>

        {/* Second div - 30% width */}
        <div className="w-full lg:w-3/10 p-2 flex flex-col items-center">
          <img
            src={PCBShowcase}
            alt="Electronic"
            className="w-64 h-auto object-contain mb-2"
          />
          <img
            src={Electric}
            alt="Electric"
            className="w-64 h-auto object-contain mb-2"
          />
          <img
            src={Electrical}
            alt="Electrical"
            className="w-64 h-auto object-contain mb-2"
          />
          <img
            src={Electronics}
            alt="Electronics"
            className="w-64 h-auto object-contain mb-2"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Product;
