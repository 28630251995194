import React from "react";
import CompanyNews from "./CompanyNews";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div>
      <div className="container mx-auto flex flex-col lg:flex-row py-5 lg:py-5 lg:mt-2 component-bg-color">
        {/* Left Side Image */}
        <div className="flex-shrink-0 mb-8 mt-5 lg:w-2/5 lg:mb-0">
          <iframe
            className="w-full h-72"
            src="http://www.youtube.com/embed/dC_xH-HfJgA"
            allowFullScreen
          ></iframe>
        </div>
        <div className="px-5"></div>
        {/* Right Side Text */}
        <div className="lg:w-3/5 mb-2 lg:pl-5 flex justify-center items-center">
          <div
            className="p-5 rounded-md bg-opacity-50 text-center"
            style={{ backgroundColor: "white" }}
          >
            <div className="p-2 rounded-md bg-opacity-50 border-1 border-gray-600">
              <h2 className="font-general-medium text-xl dark:text-primary-light font-bold block text-color">
                About Company
              </h2>
            </div>
            <p
              className="sm:text-lg lg:text-lg md:text-lg text-primary-dark w-full block lg:leading-8 lg:word-spacing-wider lg:word-spacing-wide pt-3"
              style={{ textAlign: "justify" }}
            >
              <span className="font-general-medium text-lg font-bold">
                S.B.TECHNOLOGIES
              </span>{" "}
              provides Electronics Manufacturing Services (EMS) with 0201 Chip
              Automated SMT Assembly, Micro BGA Assembly, Thru-Hole PCB
              Assembly, PCB CAD, Reflow Soldering, Wave Soldering & Mixed
              Technology Printed Circuit Board Assembly built to IPC-Workmanship
              Standards.
            </p>
            <div className="text-right mb-2">
              <Link
                to="/aboutus"
                className="mx-2 text-sm inline-block hover:text-indigo-500 underline text-color"
                style={{
                  // color: "blue",
                  textDecoration: "underline",
                }}
              >
                more
              </Link>
            </div>
          </div>
        </div>
      </div>
      <CompanyNews />
    </div>
  );
}

export default Home;
