import React from "react";
import QPolicy from "../images/quality.gif";

function QualityPolicy() {
  return (
    <div className="container mx-auto flex flex-col lg:flex-row py-5 lg:py-5 lg:mt-2">
      {/* Left Side Image */}
      <div className="w-full mb-5 lg:mb-0">
        <img
          src={QPolicy}
          alt="policy"
          className="w-full h-auto rounded-lg shadow-lg
                     lg:w-[400px] lg:h-[400px] md:w-[300px] md:h-[300px] sm:w-[250px] sm:h-[250px] mr-10"
          style={{
            border: "2px solid #7373be",
          }}
        />
      </div>
    </div>
  );
}

export default QualityPolicy;
