import { useState } from "react";
import Button from "../reusable/Button";
import FormInput from "../reusable/FormInput";
import emailjs from "emailjs-com";

const FeedbackForm = () => {
  const [formData, setFormData] = useState({
    txtName: "",
    txtOrganization: "",
    txtEmail: "",
    txtPhone: "",
    txtFax: "",
    txtUrl: "",
    txtSuggestions: "",
  });

  const [errors, setErrors] = useState({
    txtEmail: "",
    txtPhone: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = "";

    // Validation logic
    if (name === "txtEmail") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        error = "Invalid email address";
      }
    }

    if (name === "txtPhone") {
      const phoneRegex = /^(\+?\d{1,3}[-.\s]?)?(\d{10,15})$/;
      if (!phoneRegex.test(value)) {
        error = "Invalid phone number";
      }
    }

    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: error });
  };

  const isValidForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^(\+?\d{1,3}[-.\s]?)?(\d{10,15})$/;
    const validEmail = emailRegex.test(formData.txtEmail);
    const validPhone = phoneRegex.test(formData.txtPhone);
    return validEmail && validPhone;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!isValidForm()) {
      alert("Please correct the errors before submitting the form.");
      return;
    }

    emailjs
      .send(
        "service_ldd1o9f",
        "template_s5noqg2",
        {
          feedback_person_email: formData.txtEmail,
          feedback_person_name: formData.txtName,
          feedback_person_organization: formData.txtOrganization,
          feedback_person_phone: formData.txtPhone,
          feedback_person_fax: formData.txtFax,
          feedback_person_url: formData.txtUrl,
          feedback_person_suggestion: formData.txtSuggestions,
        },
        "s2NdSCcx0Mt0-RrKw"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message sent successfully!");
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send message. Please try again.");
        }
      );
  };

  return (
    <div className="w-full lg:w-1/2">
      <form
        onSubmit={sendEmail}
        className="max-w-xl px-6 sm:p-10 bg-secondary-light dark:bg-secondary-dark rounded-xl text-left"
      >
        <div className="flex justify-center bg-h1 font-general-medium text-xl mt-8 mb-8">
          Feedback Form
        </div>
        <FormInput
          inputLabel="Name"
          labelFor="txtName"
          inputType="text"
          inputId="txtName"
          inputName="txtName"
          placeholderText="Your Name"
          ariaLabelName="Name"
          value={formData.txtName}
          handleChange={handleChange}
        />
        <FormInput
          inputLabel="Organization"
          labelFor="txtOrganization"
          inputType="text"
          inputId="txtOrganization"
          inputName="txtOrganization"
          placeholderText="Organization"
          value={formData.txtOrganization}
          handleChange={handleChange}
          isRequired={false}
        />
        <FormInput
          inputLabel="Email"
          labelFor="txtEmail"
          inputType="text"
          inputId="txtEmail"
          inputName="txtEmail"
          placeholderText="Email"
          value={formData.txtEmail}
          handleChange={handleChange}
          error={errors.txtEmail}
        />
        <FormInput
          inputLabel="Phone"
          labelFor="txtPhone"
          inputType="text"
          inputId="txtPhone"
          inputName="txtPhone"
          placeholderText="Example: +911234567890, 1234567890"
          value={formData.txtPhone}
          handleChange={handleChange}
          error={errors.txtPhone}
        />
        <FormInput
          inputLabel="URL"
          labelFor="txtUrl"
          inputType="text"
          inputId="txtUrl"
          inputName="txtUrl"
          placeholderText="URL"
          value={formData.txtUrl}
          handleChange={handleChange}
          isRequired={false}
        />
        <FormInput
          inputLabel="Suggestions"
          labelFor="txtSuggestions"
          inputType="text"
          inputId="txtSuggestions"
          inputName="txtSuggestions"
          placeholderText="Suggestions"
          value={formData.txtSuggestions}
          handleChange={handleChange}
          isRequired={false}
        />
        <div className="flex justify-center items-center rounded-lg mt-6 duration-500">
          <Button
            title="Submit"
            type="submit"
            aria-label="Send Message"
            className="w-40 text-white font-medium tracking-wider bg-blue hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900"
          />
        </div>
      </form>
    </div>
  );
};

export default FeedbackForm;
