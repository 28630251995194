import React from "react";
import NewsPaper2Image from "../images/ele_bazar.jpg";

function NewsPaper1() {
  return (
    <div className="container mx-auto lg:flex-row lg:py-5 lg:mt-2">
      <div className="w-full mb-5">
        <img
          src={NewsPaper2Image}
          alt="newspaper"
          className="w-full h-auto"
        />

        <div className="flex flex-col lg:flex-row">
          <div style={{ flex: "4", padding: "10px", textAlign: "justify" }}>
            <div className="py-2 w-full flex">
              <p style={{ textAlign: "justify" }} className="font-semibold">
                <span
                  className="font-general-medium sm:text-sm text-2xl font-semibold"
                  style={{ textAlign: "justify" }}
                >
                  "Almost all fields are adopting SMD LEDs"
                </span>
                <br />
                <br />
                Established 1995, Bangalore-based S.B. Technologies offers
                electronics manufacturing services (EMS) to OEMs in telecom,
                electronics, consumer electronics, medical instruments, T&M,
                computer peripherals, electrical, defence, industrial controls,
                CNC machines, etc.
                <br />
                <br />
                In an interview with Pradeep Chakraborty of EFYTimes.com, VS
                Srikanth, chief executive, SB Technologies, details how the
                company is seeing a huge demand for SMD LEDs and SMD components
                for automobiles.
              </p>
            </div>
          </div>
          <div
            className="lg:flex-row"
            style={{ flex: "2", padding: "10px", textAlign: "justify" }}
          >
            <div className="py-2 w-full flex">
              <p style={{ textAlign: "justify" }}>
                <span
                  className="font-general-medium sm:text-sm font-semibold"
                  style={{ textAlign: "justify" }}
                >
                  EB: What are the changes that the medical devices industry is
                  undergoing that SMT euipment manufacturing industry needs to
                  understand?
                </span>
                <br />
                The SMT equipment manufacturing should have continuous updations
                on the component handling nozzles and feeders for latest
                component packages. Also, the equipment should be well equipped
                for quick changeovers and for small production runs.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row"  style={{ display: "flex" }}>
          <div style={{ flex: "2", padding: "10px", textAlign: "justify" }}>
            <div className="w-full flex">
              <p style={{ textAlign: "justify" }}>
                <span
                  className="font-general-medium sm:text-sm font-semibold"
                  style={{ textAlign: "justify" }}
                >
                  EB: What are the current EMS trends in india?
                </span>
                <br />
                In India. EMS players should focus more on flexibility and quick
                turnaround cycles. The current trend is moving towards full
                turnkey manufacturing, from design support till product
                building. There is even a good market for small production
                batches with prototyping support.
              </p>
            </div>
            <div className="py-2 w-full flex">
              <p style={{ textAlign: "justify" }}>
                <span
                  className="font-general-medium sm:text-sm font-semibold"
                  style={{ textAlign: "justify" }}
                >
                  EB:Is the demand for EMS increasing?
                </span>
                <br />I don't perceive any real increase in demand. Primarily.,
                government policies need to be changed for the EMS sector.
                Import duties need to be nullfied, and banking policies need to
                be more flexible towards EMS companies. If all these points are
                taken care of, we can surely see an increase in demand.
              </p>
            </div>
          </div>
          <div
            style={{
              flex: "2",
              paddingTop: "10px",
              textAlign: "justify",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            <div className="w-full flex">
              <p style={{ textAlign: "justify" }}>
                <span
                  className="font-general-medium sm:text-sm font-semibold"
                  style={{ textAlign: "justify" }}
                >
                  EB:What lean manufacturing practices do you follow?
                </span>
                <br />
                We follow practices for reducing overheads, which directly
                support us during lean periods. We concentrate more on design
                and development.
              </p>
            </div>
            <div className="py-2 w-full flex">
              <p style={{ textAlign: "justify" }}>
                <span
                  className="font-general-medium sm:text-sm font-semibold"
                  style={{ textAlign: "justify" }}
                >
                  EB: Are we beginning to see a large demand for SMD components
                  for automobiles?
                </span>
                <br />
                Definitely yes, although this has been going on since the past
                one year.
              </p>
            </div>
            <div className="py-2 w-full flex">
              <p style={{ textAlign: "justify" }}>
                <span
                  className="font-general-medium sm:text-sm font-semibold"
                  style={{ textAlign: "justify" }}
                >
                  EB: What are the application areas for SMD LEDs, especially in
                  the Indian context?
                </span>
                <br />
                SMD LEDs are used in a wide range of application starting from
                the consumer market to the industrial sector.Almost all the
                fields are adopting SMD LEDs because of their durability and
                size.These include industrial lighting. Solar lighting,
                automation, medical electronics, power sector, etc.
              </p>
            </div>
          </div>
          <div style={{ flex: "2", padding: "10px", textAlign: "justify" }}>
            <div className="w-full flex">
              <p style={{ textAlign: "justify" }}>
                <span
                  className="font-general-medium sm:text-sm font-semibold"
                  style={{ textAlign: "justify" }}
                >
                  EB: What's happening in the chemical/biological detection as
                  well as proteomics/genomics areas?
                </span>
                <br />
                We have been continuously working with clients for their
                manufacturing and development activities for global positioning
                devices, sensors, etc.
              </p>
            </div>
            <div className="py-2 w-full flex">
              <p style={{ textAlign: "justify" }}>
                <span
                  className="font-general-medium sm:text-sm font-semibold"
                  style={{ textAlign: "justify" }}
                >
                  EB: What are your thoughts on the Indian electronics
                  manufacturing scene?
                </span>
                <br />
                Even though the Indian market is picking up in a slow pace,
                definitely, we are all looking at the bright future for the
                industry. We have been continuously improving our process by
                upgrading our infrastructure with latest cutting-edge technology
                machines.
              </p>
            </div>
            <div className="py-2 w-full flex">
              <p style={{ textAlign: "justify" }}>
                <span
                  className="font-general-medium sm:text-sm font-semibold"
                  style={{ textAlign: "justify" }}
                >
                  EB: What are the latest projects that S.B. Technologies is
                  currently involved in?
                </span>
                <br />
                S.B. Technologies is presently involved with projects for GPS
                devices, engineering embedded projects, child tracking systems,
                fire protection sensors, conversion of through hole technology
                to latest SMT cards with development supports.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsPaper1;
