import React from "react";

const Button = ({ title, type, ariaLabel, className, onClick }) => {
  return (
    <button
      type={type}
      aria-label={ariaLabel}
      onClick={onClick}
      className={`px-4 py-2.5 rounded-lg duration-500 ${className}`}
    >
      {title}
    </button>
  );
};

export default Button;
