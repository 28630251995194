import React from "react";

const FormInput = ({
  inputLabel,
  labelFor,
  inputType,
  inputId,
  inputName,
  placeholderText,
  ariaLabelName,
  value,
  handleChange,
  isRequired = true, // Default to true if not provided
  error,
}) => {
  return (
    <div className="flex flex-col sm:flex-row items-start sm:items-center mb-4">
      <label
        className="text-lg text-primary-dark dark:text-primary-light mb-2 sm:mb-0 sm:mr-2 flex-shrink-0 mr-4"
        htmlFor={labelFor}
      >
        {inputLabel}
        {isRequired && <span style={{ color: "red" }}>*</span>}
      </label>
      {inputType === "textarea" ? (
        <textarea
          className={`w-full sm:flex-1 px-5 py-2 border ${
            error ? "border-red-800" : "border-gray-500"
          } dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md`}
          id={inputId}
          name={inputName}
          placeholder={placeholderText}
          aria-label={ariaLabelName}
          value={value}
          onChange={handleChange}
          required={isRequired}
        />
      ) : (
        <input
          className={`w-full sm:flex-1 px-5 py-2 border ${
            error ? "border-red-800" : "border-gray-500"
          } dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md`}
          type={inputType}
          id={inputId}
          name={inputName}
          placeholder={placeholderText}
          aria-label={ariaLabelName}
          value={value}
          onChange={handleChange}
          required={isRequired}
        />
      )}
      
    </div>
  );
};

export default FormInput;
