import { motion } from "framer-motion";
import ContactDetails from "../contact/ContactDetails";
import Lottie from "react-lottie";
import Contactus from "../lottie/contact.json";

const Contact = () => {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: Contactus,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        ease: "easeInOut",
        duration: 0.5,
        delay: 0.1,
      }}
      className="container mx-auto flex flex-col-reverse lg:flex-row py-5 lg:py-10 lg:mt-5"
    >
      <ContactDetails />
      <Lottie options={lottieOptions} height={"100%"} width={"50%"} />
    </motion.div>
  );
};

export default Contact;
