import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import AppFooter from "./components/shared/AppFooter";
import AppHeader from "./components/shared/AppHeader";
import "./components/css/App.css";
import UseScrollToTop from "./hooks/useScrollToTop";
import Home from "./components/pages/Home";
import Contact from "./components/pages/Contact";
import AboutUs from "./components/pages/AboutUs";
import Carousel from "./components/pages/Carousel";
import Enquiry from "./components/pages/Enquiry";
import Feedback from "./components/pages/Feedback";
import QualityPolicy from "./components/pages/QualityPolicy";
import News from "./components/pages/News";
import Facility from "./components/pages/Facility";
import Product from "./components/pages/Product";
import NotFound from "./components/NotFound";
import Sitemap from "./components/pages//Sitemap"

const pageTitles = {
  "/": "Home", // Customize these titles as needed
  "/aboutus": "About Us",
  "/contact": "Contact",
  "/enquiry": "Enquiry",
  "/feedback": "Feedback",
  "/qualitypolicy": "Quality Policy",
  "/news": "News",
  "/facility": "Facility",
  "/product": "Product",
  "/sitemap": "Sitemap",
  "/sitemap.html": "Sitemap",
};

function MainContent() {
  const location = useLocation();
  const currentPath = location.pathname;

  const pageTitle = pageTitles[currentPath] || "Page Not Found";

  const metaTags = {
    title: pageTitle,
    description: "SB technologies, bangalore, india, offers pcb manufacturing and automated reflow soldering with through hole and smd technology,Printed Circuit Board Assembly & Manufacturing,sb technology,cad,PCB Wave Soldering &amp; Reflow Soldering Services,Electronic Manufacturing Services EMS to our clients across the globe.",
    keywords: "SMT,PCB,PCB ASSEMBLY, SERVICES, EMS, BANGALORE, SMD, WAVE SOLDERING, SOLDERING SERVICES, ELECTRONICS MANUFACTURING SERVICES, BGA ASSEMBLY, QFP, qfn, no lead packages, pick &amp; place, PLACE,JUKI, 0402 0603 0805 1206 CHIP SMD, ASSEMBLED CARD MANUFACTURING, SBTECH, SB TECHNOLOGIES, SMD ASSEMBLY, PCB ASSEMBLY SERVICES, SMD ASSEMBLY SERVICES, CONTRACT MANUFACTURING INDIA, sb technology,Circuit Board Assembly, rework, proto development, cad design, cam design, pick and place machines, automated pcb assembly services in india,pcb fabrication, multi layer pcb, Reflow Soldering Services in bangalore,ems companies bangalore, reflow, pcb desisgn company in bangalore,ipca 610 d,ipc standard in bangalore, orcad, pcad, pads, cad training, pcb design company in bangalore, pcb cad design companies in bangalore, embedded system development, microcontroller, microprocessor, development board",
    robots: "index,follow",
    author: "sb technologies, Bangalore",
    classification: "Printed Circuit Board Assembly & Manufacturing, pcb, Electronic Manufacturing Services EMS, Bangalore, INDIA",
    generator: "Adobe Dreamweaver CS5",
    owner: "Srikanth sb technologies",
    contentLanguage: "en-us",
    distribution: "Global",
  };

  return (
    <>
      <Helmet>
        <title>{metaTags.title}</title>
        <meta name="description" content={metaTags.description} />
        <meta name="keywords" content={metaTags.keywords} />
        <meta name="robots" content={metaTags.robots} />
        <meta name="author" content={metaTags.author} />
        <meta name="classification" content={metaTags.classification} />
        <meta name="generator" content={metaTags.generator} />
        <meta name="owner" content={metaTags.owner} />
        <meta httpEquiv="Content-Language" content={metaTags.contentLanguage} />
        <meta name="distribution" content={metaTags.distribution} />
        <meta property="og:title" content={metaTags.title} />
        <meta property="og:description" content={metaTags.description} />
      </Helmet>
      {currentPath === '/' && <Carousel />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="aboutus" element={<AboutUs />} />
        <Route path="contact" element={<Contact />} />
        <Route path="enquiry" element={<Enquiry />} />
        <Route path="feedback" element={<Feedback />} />
        <Route path="qualitypolicy" element={<QualityPolicy />} />
        <Route path="news" element={<News />} />
        <Route path="facility" element={<Facility />} />
        <Route path="product" element={<Product />} />
        <Route path="sitemap" element={<Sitemap />} />
        <Route path="sitemap.html" element={<Sitemap />} /> 
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

function App() {
  return (
    <div className="bg-secondary-light dark:bg-primary-dark transition duration-300">
      <Router>
        <ScrollToTop />
        <AppHeader />
        <MainContent />
        <AppFooter />
      </Router>
      <UseScrollToTop />
    </div>
  );
}

export default App;
