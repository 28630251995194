import React from "react";
import { motion } from "framer-motion";
import "./Facility.css";

const Facility = () => {
  const data = [
    ["1", "High Speed Chip Shooter", "YAMAHA, JAPAN", "40000 CPH", "2008", "1"],
    ["2", "Solder Paste Printer", "DEK 248", '23" Square', "2006", "1"],
    ["3", "Wave Soldering Machine", "TMPL", "Max. 265mm", "1996", "1"],
    [
      "4",
      "Reflow Soldering Machine",
      "EMS Technologies",
      "Max. 450 mm Sq.",
      "2002",
      "1",
    ],
    [
      "5",
      "Automatic Dip Soldering",
      "Estovir Controls",
      "Max. 250mm",
      "2000",
      "1",
    ],
    [
      "6",
      "Fully Computerised Semi Auto SMT Pick and Placer",
      "Preciplacer, Switzerland",
      "264X300",
      "2001",
      "1",
    ],
    [
      "7",
      "Stencil Printer",
      "Weighwell Instruments",
      "Max 21 Sq. Inch",
      "2001",
      "2",
    ],
    [
      "8",
      "Temperature Controlled Soldering Station",
      "Hakko, Japan",
      "4000 C",
      "2000",
      "2",
    ],
    [
      "9",
      "Temperature Controlled Soldering Station",
      "Klapp",
      "4000 C",
      "1999",
      "6",
    ],
    ["10", "Curing Oven", "-", "3000 C", "1996", "1"],
    ["11", "Ultrasonic Cleaner", "SIDILU Ultrasonic", "5 Ltrs.", "2001", "1"],
    ["12", "Servo Stablizer", "Power Engineers", "2 KVA", "1998", "1"],
    ["13", "Inverter", "Ellen Corporation", "1KVA", "1998", "1"],
    ["14", "Compressor 1hp, 120 PSI", "AIRMATIC", "1hp, 120 PSI", "1996", "1"],
    ["15", "Air Blower", "-", "1 KW", "1998", "1"],
    ["16", "Toggle Press", "General Engg. Company", "-", "2000", "1"],
    [
      "17",
      "Axial Lead Former",
      "Time Engineers/ Estovir Controls",
      "-",
      "1996",
      "2",
    ],
    ["18", "Radial Lead Former", "Time Engineers", "-", "1996", "1"],
    ["19", "Jumper former", "Estovir Controls", "-", "1998", "1"],
    [
      "20",
      "Temperature Controlled De Soldering Station",
      "Surya Tools and Equipments",
      "4000 C",
      "1998",
      "1",
    ],
    ["21", "Digital Multimeter", "MASTECH", "-", "1998", "1"],
    ["22", "Analog Multimeter", "AGRO", "-", "1996", "1"],
    [
      "23",
      "PCB Assembly Conveyors Line",
      "ESTOVIR Controls",
      "18 Ft.",
      "1998",
      "2",
    ],
    [
      "24",
      "Illuminated Magnifier",
      "Optics & Allied Engg. P.Ltd.",
      "6X",
      "2000",
      "3",
    ],
    [
      "25",
      "Magnifying Lens",
      "Optics & Allied Engg. P.Ltd.",
      "10X",
      "2000",
      "5",
    ],
    ["26", "Lead Cutter", "XURON", "1.0mm", "2001", "2"],
    ["27", "Manual Kinking Tool", "XURON", "-", "2001", "1"],
    ["28", "Wire Stripper", "BEST, Taiwan", "-", "2001", "1"],
    [
      "29",
      "SMT Rework Station",
      "QUICK, China",
      "All QFP’S, PLCC etc",
      "2002",
      "1",
    ],
    ["30", "Diesel Generator", "Swaraj", "8HP", "2003", "1"],
    ["31", "Stereo Microscope", "LENSEL", "40X", "2005", "1"],
    ["32", "SMD Glue Dispenser", "TWIN Engineers", "-", "2002", "1"],
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        ease: "easeInOut",
        duration: 0.5,
        delay: 0.1,
      }}
      className="container mx-auto flex flex-col py-5 lg:py-10 lg:mt-5 component-bg-color"
    >
      <div className="overflow-x-auto" style={{ backgroundColor: "white" }}>
        <table className="w-full table-auto border" id="facility-table">
          <thead>
            <tr>
              {[
                "Sl.No.",
                "Machine",
                "Make",
                "Capacity",
                "Year of Installation",
                "Quantity",
              ].map((heading) => (
                <th
                  key={heading}
                  className="table-header py-2 px-2 sm:px-4 border bg-icon-gray font-semibold text-xs sm:text-sm md:text-base"
                >
                  {heading}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td
                    key={cellIndex}
                    className="table-cell py-1 px-2 sm:px-4 border text-xs sm:text-sm md:text-base whitespace-nowrap"
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </motion.div>
  );
};

export default Facility;
