import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "../../components/images/menu-img-1.jpg";
import image2 from "../../components/images/menu-img-2.jpg";
import image3 from "../../components/images/menu-img-3.jpg";
import image4 from "../../components/images/menu-img-4.jpg";
import image5 from "../../components/images/menu-img-5.jpg";
import BgImage from "../../components/images/carasoul-bg-image.jpg";

const images = [
  { src: image1, alt: "Slide 1" },
  { src: image2, alt: "Slide 2" },
  { src: image3, alt: "Slide 3" },
  { src: image4, alt: "Slide 4" },
  { src: image5, alt: "Slide 5" },
];

const Carousel = () => {
  const settings = {
    slidesToShow: 1,
    speed: 500,
    dots: false,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "40px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "30px",
        },
      },
    ],
  };

  return (
    <div
      className="container mx-auto py-5 flex justify-center items-center"
      style={{
        backgroundImage: `url(${BgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "700px",
          marginTop: "30px",
          marginBottom: "30px",
        }}
      >
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="relative group">
              <img
                src={image.src}
                alt={image.alt}
                className="object-cover rounded-lg border border-indigo-200 w-full"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;
