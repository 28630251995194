import {
  FiMapPin,
  FiMail,
  FiSmartphone,
} from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";

const contacts = [
  {
    id: 1,
    name: (
      <span>
        <span className="font-general-medium text-xl dark:text-primary-light text-customColor font-bold block">
          S.B. Technologies
        </span>
        #4, 9thMain, 8th Cross, J.C. Industrial Estate, Kanakapura Main Road,
        Yelachenahalli, Bangalore - 560062, India
      </span>
    ),
    icon: <FiMapPin />,
  },
  {
    id: 2,
    name: "info@sbtechindia.com",
    icon: <FiMail />,
  },
  {
    id: 5,
    name: "+91-9845779326",
    icon: <FiSmartphone />,
  },
  {
    id: 6,
    name: "+91 73537 75422",
    icon: <FaWhatsapp />,
  },
];

const ContactDetails = () => {
  return (
    <div className="w-full lg:w-1/2">
      <div className="text-left max-w-xl px-6">
        <h2 className="font-general-medium text-xl text-primary-dark dark:text-primary-light mt-12 mb-8">
          Contact details
        </h2>
        <ul className="font-general-regular">
          {contacts.map((contact) => (
            <li className="flex " key={contact.id}>
              <i className="text-2xl text-secondary-dark dark:text-gray-400 mr-4">
                {contact.icon}
              </i>
              <span className="text-lg mb-4 text-ternary-dark dark:text-ternary-light">
                {contact.name}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ContactDetails;
