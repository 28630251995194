import React from 'react';
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import PagNotFound from "./lottie/PageNotFound.json"

const NotFound = () => {
    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: PagNotFound,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        ease: "easeInOut",
        duration: 0.5,
        delay: 0.1,
      }}
      className="container mx-auto"
    >
      <Lottie options={lottieOptions} height={"100%"} width={"100%"} />
    </motion.div>
  );
};

export default NotFound;
