import React from "react";
import Sbtech from "../images/sbtech.gif";

function AboutUs() {
  return (
    <div className="container mx-auto flex flex-col lg:flex-row py-5 lg:py-5 lg:mt-2 component-bg-color">
      {/* Left Side Image */}
      <div className="flex-shrink-0 lg:w-1/2 mb-5 lg:mb-0">
        <img
          src={Sbtech}
          alt="S.B.TECHNOLOGIES"
          className="w-full h-auto rounded-lg shadow-lg"
          style={{
            border: "1px solid #7373be",
          }}
        />
      </div>
      <div className="px-5"></div>
      {/* Right Side Text */}
      <div className="lg:w-1/2 lg:pl-5 flex justify-center items-center">
        <div
          className="p-5 rounded-md bg-opacity-50 text-center"
          style={{ backgroundColor: "white" }}
        >
          <p
            className="sm:text-lg lg:text-lg md:text-lg text-primary-dark w-full block lg:leading-8 lg:word-spacing-wider lg:word-spacing-wide pt-3"
            style={{ textAlign: "justify" }}
          >
            <span className="font-general-medium text-lg font-bold">
              S.B.TECHNOLOGIES
            </span>{" "}
            is a Bangalore based company established in the year 1995, offers
            Electronics Manufacturing Services to OEMs in Telecommunication
            Industry, Electronics, Test and Measurement, Electrical, Defence,
            Computer Peripherals, Industrial Controls, Medical Instruments,
            Consumer Electronics, CNC Machines etc.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
