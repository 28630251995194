// components/pages/Sitemap.js
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";


const Sitemap = () => {
  const routes = [
    { path: '/', name: 'Home' },
    { path: '/aboutus', name: 'About Us' },
    { path: '/contact', name: 'Contact' },
    { path: '/enquiry', name: 'Enquiry' },
    { path: '/feedback', name: 'Feedback' },
    { path: '/qualitypolicy', name: 'Quality Policy' },
    { path: '/news', name: 'News' },
    { path: '/facility', name: 'Facility' },
    { path: '/product', name: 'Product' },
    // Add other routes here
  ];

  return (
    <motion.nav
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      id="nav"
      className="sm:container sm:mx-auto bg-white border-b-2 border-gray-200"
    >
      <ul>
        {routes.map((route) => (
          <li key={route.path}>
            <Link to={route.path}>{route.name}</Link>
          </li>
        ))}
      </ul>
      </motion.nav>
  );
};

export default Sitemap;
