import { Link } from "react-router-dom";

const AppFooter = () => {
  return (
    <div className="container mx-auto border-t-2 border-gray-200">
      <div className="pt-10 sm:pt-20 pb-8 mt-10 dark:border-secondary-dark">
        <div className="font-general-regular flex flex-col items-center text-center sm:flex-row sm:justify-between sm:text-left">
          <div className="mb-4 sm:mb-0 text-sm sm:text-lg text-ternary-dark dark:text-ternary-light">
            &copy; {2006}
            <a
              className="ml-2 "
            >
              S.B.Technologies
            </a>
          </div>
          <div className="flex flex-wrap justify-center text-sm sm:text-lg sm:justify-end">
            <div className="w-full sm:w-auto sm:flex sm:flex-row">
              <div className="flex flex-wrap justify-center">
                
                <Link
                  to="/aboutus"
                  className="ml-2 mr-2 font-medium text-indigo-600 duration-500 hover:underline"
                >
                  AboutUs
                </Link>
                /
                <Link
                  to="/contact"
                  className="ml-2 mr-2 font-medium text-indigo-600 duration-500 hover:underline"
                >
                  Contact
                </Link>
                /
                <Link
                  to="/enquiry"
                  className="ml-2 mr-2 font-medium text-indigo-600 duration-500 hover:underline"
                >
                  Enquiry
                </Link>
                /
                <Link
                  to="/news"
                  className="ml-2 mr-2 font-medium text-indigo-600 duration-500 hover:underline"
                >
                  News
                </Link>
                /
                <Link
                  to="/facility"
                  className="ml-2 mr-2 font-medium text-indigo-600 duration-500 hover:underline"
                >
                  Facility
                </Link>
              </div>
              <div className="flex flex-wrap justify-center">
                /
                <Link
                  to="/product"
                  className="ml-2 mr-2 font-medium text-indigo-600 duration-500 hover:underline"
                >
                  Product
                </Link>
                /
                <Link
                  to="/qualitypolicy"
                  className="ml-2 mr-2 font-medium text-indigo-600 duration-500 hover:underline"
                >
                  Quality Policy
                </Link>
                /
                <Link
                  to="/sitemap"
                  className="ml-2 mr-2 font-medium text-indigo-600 duration-500 hover:underline"
                >
                  Sitemap
                </Link>
                /
                <Link
                  to="/feedback"
                  className="ml-2 mr-2 font-medium text-indigo-600 duration-500 hover:underline"
                >
                  Feedback
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
