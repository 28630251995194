import React from "react";
import NewsPaper from "../images/newspaper.png";
import NewsPaper1 from "../images/newspaper1.png";
import ArticlePage3 from "../newspaper/NewsPaper1";
import ArticlePage4 from "../newspaper/NewsPaper2";

function Art() {
  return (
    <div className="container mx-auto py-5 lg:mt-2 component-bg-color">
      <div className="flex flex-col md:flex-row md:space-x-4 mb-5">
        <div className="w-full md:w-1/2 mb-5 md:mb-0 max-h-screen md:h-auto">
          <img
            src={NewsPaper}
            alt="newspaper"
            className="w-full h-full object-cover rounded-lg border border-slate-300"
          />
        </div>
        <div className="w-full md:w-1/2 max-h-screen md:h-auto">
          <img
            src={NewsPaper1}
            alt="newspaper"
            className="w-full h-full object-cover rounded-lg border border-slate-300"
          />
        </div>
      </div>
      <div style={{ backgroundColor: "white" }}>
        <ArticlePage3 />
      </div>
      <div style={{ backgroundColor: "white" }}>
        <ArticlePage4 />
      </div>
    </div>
  );
}

export default Art;
