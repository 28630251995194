import { useState } from "react";
import Button from "../reusable/Button";
import FormInput from "../reusable/FormInput";
import emailjs from "emailjs-com";

const EnquiryContactForm = () => {
  const [formData, setFormData] = useState({
    txtName: "",
    txtCompany: "",
    txtDesign: "",
    txtAdres: "",
    lstCountry: "India",
    txtPhone: "",
    txtFax: "",
    txtEmail: "",
    txtReason: "SMT PCB ASSEMBLY",
    txtRemarks: "",
  });

  const [errors, setErrors] = useState({
    txtEmail: "",
    txtPhone: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = "";

    // Validation logic
    if (name === "txtEmail") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        error = "Invalid email address";
      }
    }

    if (name === "txtPhone") {
      const phoneRegex = /^\d{10}$/;
      if (!phoneRegex.test(value)) {
        error = "Phone number must be 10 digits";
      }
    }

    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: error });
  };

  const isValidForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;
    const validEmail = emailRegex.test(formData.txtEmail);
    const validPhone = phoneRegex.test(formData.txtPhone);
    return validEmail && validPhone;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!isValidForm()) {
      alert("Please correct the errors before submitting the form.");
      return;
    }

    emailjs
      .send(
        "service_ldd1o9f",
        "template_yocf6hw",
        {
          enquiry_person_email: formData.txtEmail,
          enquiry_person_name: formData.txtName,
          enquiry_person_organization: formData.txtCompany,
          enquiry_person_phone: formData.txtPhone,
          enquiry_person_designation: formData.txtDesign,
          enquiry_person_address: formData.txtAdres,
          enquiry_person_country: formData.lstCountry,
          enquiry_person_fax: formData.txtFax,
          enquiry_person_reason: formData.txtReason,
          enquiry_person_remarks: formData.txtRemarks,
        },
        "s2NdSCcx0Mt0-RrKw"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message sent successfully!");
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send message. Please try again.");
        }
      );
  };

  return (
    <div className="w-full lg:w-1/2">
      <form
        onSubmit={sendEmail}
        className="max-w-xl px-6 sm:p-10 bg-secondary-light dark:bg-secondary-dark rounded-xl text-left"
      >
        <p className="flex justify-center bg-h1 font-general-medium text-xl mt-8 mb-8">
          Enquiry Form
        </p>
        <FormInput
          inputLabel="Name"
          labelFor="txtName"
          inputType="text"
          inputId="txtName"
          inputName="txtName"
          placeholderText="Your Name"
          ariaLabelName="Name"
          value={formData.txtName}
          handleChange={handleChange}
        />
        <FormInput
          inputLabel="Company"
          labelFor="txtCompany"
          inputType="text"
          inputId="txtCompany"
          inputName="txtCompany"
          placeholderText="Company"
          value={formData.txtCompany}
          handleChange={handleChange}
        />
        <FormInput
          inputLabel="Designation"
          labelFor="txtDesign"
          inputType="text"
          inputId="txtDesign"
          inputName="txtDesign"
          placeholderText="Designation"
          value={formData.txtDesign}
          handleChange={handleChange}
          isRequired={false}
        />
        <FormInput
          inputLabel="Address"
          labelFor="txtAdres"
          inputType="textarea"
          inputId="txtAdres"
          inputName="txtAdres"
          placeholderText="Address"
          value={formData.txtAdres}
          handleChange={handleChange}
          isRequired={false}
        />
        <div className="flex flex-col sm:flex-row items-start sm:items-center mb-4">
          <div className="relative sm:w-40 flex">
            <label
              htmlFor="lstCountry"
              className="text-md text-primary-dark dark:text-primary-light mb-2 sm:mb-0 sm:mr-4 flex-shrink-0 mr-4"
              style={{ maxWidth: "70px" }}
            >
              Country
              <span className="absolute top-0 right-0 text-red">*</span>
            </label>
          </div>
          <select
            name="lstCountry"
            id="lstCountry"
            className="w-full sm:flex-1 px-5 py-2 border border-gray-500 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md"
            value={formData.lstCountry}
            onChange={handleChange}
            required
          >
            <option value="India">India</option>
            <option value="International">International</option>
          </select>
        </div>
        <FormInput
          inputLabel="Phone"
          labelFor="txtPhone"
          inputType="text"
          inputId="txtPhone"
          inputName="txtPhone"
          placeholderText="Example: +911234567890, 1234567890"
          value={formData.txtPhone}
          handleChange={handleChange}
          error={errors.txtPhone}
        />
        <FormInput
          inputLabel="Email"
          labelFor="txtEmail"
          inputType="text"
          inputId="txtEmail"
          inputName="txtEmail"
          placeholderText="Email"
          value={formData.txtEmail}
          handleChange={handleChange}
          error={errors.txtEmail}
        />
        <div className="flex flex-col sm:flex-row items-start sm:items-center mb-4">
          <div className="relative sm:w-auto" style={{ width: "240px" }}>
            <label
              htmlFor="txtReason"
              className="text-md text-primary-dark dark:text-primary-light mb-2 sm:mb-0 sm:mr-4 flex-shrink-0 mr-4"
            >
              Enquiry regarding
              <span className="absolute top-0 right-0 text-red">*</span>
            </label>
          </div>
          <select
            name="txtReason"
            id="txtReason"
            className="w-full mt-2 sm:flex-1 px-5 py-4 border border-gray-500 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md"
            value={formData.txtReason}
            onChange={handleChange}
            required
          >
            <option value="SMT PCB ASSEMBLY">SMT PCB ASSEMBLY</option>
            <option value="THRU-HOLE PCB ASSEMBLY">
              THRU-HOLE PCB ASSEMBLY
            </option>
            <option value="MIXED TECHNOLOGY CARDS">
              MIXED TECHNOLOGY CARDS
            </option>
            <option value="REQUEST FOR QUOTATION">REQUEST FOR QUOTATION</option>
            <option value="TURNKEY ASSEMBLY">TURNKEY ASSEMBLY</option>
            <option value="PRODUCT DEVELOPMENT">PRODUCT DEVELOPMENT</option>
            <option value="OTHERS">OTHERS</option>
          </select>
        </div>
        <FormInput
          inputLabel="Remarks"
          labelFor="txtRemarks"
          inputType="textarea"
          inputId="txtRemarks"
          inputName="txtRemarks"
          placeholderText="Remarks"
          value={formData.txtRemarks}
          handleChange={handleChange}
          isRequired={false}
        />
        <div className="flex justify-center items-center rounded-lg mt-6 duration-500">
          <Button
            title="Submit"
            type="submit"
            aria-label="Send Message"
            className="w-40 text-white font-medium tracking-wider bg-blue hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900"
          />
        </div>
      </form>
    </div>
  );
};

export default EnquiryContactForm;
