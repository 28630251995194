import { useState } from "react";
import { FiMenu, FiX, FiHome, FiPhone, FiMail, FiUser } from "react-icons/fi";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import sbtxtGif from "../images/logo.png";

const AppHeader = () => {
  const [showMenu, setShowMenu] = useState(false);

  function toggleMenu() {
    setShowMenu(!showMenu);
  }

  return (
    <motion.nav
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      id="nav"
      className="sm:container sm:mx-auto bg-white border-b-2 border-gray-200"
    >
      <div className="z-10 max-w-screen-lg xl:max-w-screen-xl block sm:flex sm:justify-between sm:items-center py-3">
        {/* Logo or brand name (if any) can be placed here */}
        <div className="flex justify-between items-center px-4 sm:px-0">
          {/* Adobe GIF */}
          <div className="flex items-center justify-center">
            <Link to="/">
              <img
                src={sbtxtGif} // Using the imported image
                alt="SBLOGO"
                className=" w-20 max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg hover:cursor-pointer"
              />
            </Link>
            <div className="ml-2 hidden md:flex justify-center items-center">
              <h1 className="w-full text-3xl font-semibold max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg h-auto bg-h1">
                S.B. TECHNOLOGIES
              </h1>
            </div>
          </div>

          {/* Small screen hamburger menu */}
          <div className="sm:hidden">
            <button
              onClick={toggleMenu}
              type="button"
              className="focus:outline-none"
              aria-label="Hamburger Menu"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="h-7 w-7 fill-current dark:text-ternary-light"
              >
                {showMenu ? (
                  <FiX className="text-3xl" />
                ) : (
                  <FiMenu className="text-3xl" />
                )}
              </svg>
            </button>
          </div>
        </div>

        <div
          className={
            showMenu
              ? "block m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 justify-center items-center shadow-lg sm:shadow-none"
              : "hidden"
          }
        >
          <Link
            to="/"
            className="group text-left text-lg text-black dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-light sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark flex items-center"
            aria-label="Home"
          >
            <FiHome className="mr-2 group-hover:text-secondary-dark dark:group-hover:text-secondary-light icon-color" />
            Home
          </Link>
          <Link
            to="/contact"
            className="group text-left text-lg text-black dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-light sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark flex items-center"
            aria-label="Contact"
          >
            <FiPhone className="mr-2 group-hover:text-secondary-dark dark:group-hover:text-secondary-light icon-color" />
            Contact
          </Link>
          <Link
            to="/enquiry"
            className="group text-left text-lg text-black dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-light sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark flex items-center"
            aria-label="Contact"
          >
            <FiMail className="mr-2 group-hover:text-secondary-dark dark:group-hover:text-secondary-light icon-color" />
            Enquiry
          </Link>
          <Link
            to="/aboutus"
            className="group text-left text-lg text-black dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-light sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark flex items-center"
            aria-label="About"
          >
            <FiUser className="mr-2 group-hover:text-secondary-dark dark:group-hover:text-secondary-light icon-color" />
            About Us
          </Link>
        </div>

        {/* Header links large screen */}
        <div className="font-general-medium hidden sm:flex p-5 sm:p-0 justify-center items-center ml-auto">
          {/* <Link
            to="/"
            className="group hover-bg-change text-left text-lg text-black dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-dark sm:mx-4 mb-2 sm:py-2 flex items-center m-2 rounded"
            aria-label="Home"
          >
            <div className="icon-container p-2 rounded flex items-center justify-center">
              <FiHome className="group-hover:text-secondary-dark dark:group-hover:text-secondary-dark text-white" />
            </div>
            <span className="ml-2">Home</span>
          </Link> */}
          <Link
            to="/"
            className=" text-left text-lg text-black dark:text-ternary-light flex items-center"
            aria-label="Home"
          >
            <span className="group hover-bg-change hover:text-secondary-dark dark:hover:text-secondary-dark">
              Home
            </span>
          </Link>
          <Link
            to="/aboutus"
            className=" text-left text-lg text-black dark:text-ternary-light flex items-center"
            aria-label="About"
          >
            <div className="flex items-center justify-center ml-2 mr-2">|</div>
            <span className="group hover-bg-change hover:text-secondary-dark dark:hover:text-secondary-dark">
              About Us
            </span>
          </Link>
          <Link
            to="/enquiry"
            className=" text-left text-lg text-black dark:text-ternary-light flex items-center"
            aria-label="Enquiry"
          >
            <div className="flex items-center justify-center ml-2 mr-2">|</div>
            <span className="group hover-bg-change hover:text-secondary-dark dark:hover:text-secondary-dark">
              Enquiry
            </span>
          </Link>
          <Link
            to="/contact"
            className=" text-left text-lg text-black dark:text-ternary-light flex items-center"
            aria-label="Contact"
          >
            <div className="flex items-center justify-center ml-2 mr-2">|</div>
            <span className=" text-secondary-dark">Contact</span>
          </Link>
        </div>
      </div>
    </motion.nav>
  );
};

export default AppHeader;
