import React from "react";
import Checkboard from "../images/mother-board-5496177_1920.jpg";
import { Link } from "react-router-dom";

function CompanyNews() {
  return (
    <div className="container mx-auto flex flex-col lg:flex-row lg:py-5 component-bg-color">
      {/* Left Side Image */}
      <div className="flex-shrink-0 flex justify-center items-center lg:w-3/5 mb-8 lg:mb-0">
        <div
          className="p-5 rounded-md bg-opacity-50 text-center"
          style={{ backgroundColor: "white" }}
        >
          <h2 className="font-general-medium text-xl dark:text-primary-light font-bold block text-color">
            Company News
          </h2>
          <p
            className="sm:text-lg lg:text-lg md:text-lg text-primary-dark w-full block lg:leading-8 lg:word-spacing-wider lg:word-spacing-wide pt-3"
            style={{ textAlign: "justify" }}
          >
            <div className="flex justify-center font-general-medium text-md text-color font-semibold">
              Interview - Global SMT & Packaging Sept 2012
            </div>{" "}
            provides Electronics Manufacturing Services (EMS) with 0201 Chip
            Automated SMT Assembly, Micro BGA Assembly, Thru-Hole PCB Assembly,
            PCB CAD, Reflow Soldering, Wave Soldering & Mixed Technology Printed
            Circuit Board Assembly built to IPC-Workmanship Standards.
          </p>
          <div className="text-right mb-2">
            <Link
              to="/news"
              className="text-sm inline-block hover:text-indigo-500 underline text-color"
              style={{
                // color: "blue",
                textDecoration: "underline",
              }}
            >
              more
            </Link>
          </div>
        </div>
      </div>
      <div className="px-5"></div>
      {/* Right Side Text */}
      <div className="lg:w-2/5 mb-8">
        <img
          src={Checkboard}
          alt="Video Thumbnail"
          className="w-full h-75 border border-gray-200"
        />
      </div>
    </div>
  );
}

export default CompanyNews;
