import React from "react";
import SrikanthImage from "../images/srikanthigm.gif";

function NewsPaper2() {
  return (
    <div className="container mx-auto flex flex-col lg:flex-row py-5">
      <div className="w-full mb-5">
        <hr className="border-2 border-gray-600 mb-5" />
        <span
          className="font-general-medium text-lg font-semibold"
          style={{ color: "orange", marginTop: "5px" }}
        >
          Article published in Electronics for you
        </span>
        <div className="py-2 w-full flex flex-col sm:flex-row items-center sm:items-start">
          <img
            src={SrikanthImage}
            alt="srikanth image"
            className="h-auto rounded-md border border-gray-400 mb-4 sm:mb-0 sm:mr-4"
          />
          <div className="flex flex-col text-center sm:text-left items-center sm:items-start sm:pl-4">
            <span className="font-semibold text-lg sm:text-xl" style={{ color: "orange",marginLeft:"5px" }}>
              V.S Srikanth
            </span>
            <span className="text-sm font-thin sm:mt-0 lg:mt-1">
              Chief Executive
            </span>
          </div>
        </div>

        <p className="text-justify" style={{ textAlign: "justify" }}>
          <br />
          To cater to the increased numbers, automobile manufacturers are
          switching to 48V systems. The near future will see a large demand for
          SMD component for automobiles.
          <br />
          <br />
          SMD LEDs illuminate the keypads and liquid crystal displays (LCDs) of
          mobile phones, personal digital assistants (PDAs) and modems in
          pleasant colours. In concert with light pipes, these also enchantingly
          light up the facia of car audio, CD and DVD players. Super-bright LEDs
          need just a milliampere of current to burst out in colour.
          <br />
          <br />
          Bigger SMD LEDs are used for high-resolution information boards inside
          metro trains and tourist buses. Decorative lighting applications are
          also experiencing a boom, thanks to the unique ways in which SMD LEDs
          can be built into objects. White SMD LEDs are increasingly being used
          for elegantly designed reading lamps and ultra-thin wall tile lamps.
          <br />
          <br />
          The medical devices industry is undergoing important changes that SMT
          equipment manufacturing need to understand. This industry is growing
          at a rate of 8 per cent annually. USA is the largest market, followed
          closely by Western Europe and Japan. The medical device market has
          become a safe haven for some SMT equipment manufacturers.
          <br />
          <br />
          There has been a lot of R&D in biochips for medical electronics. SMT
          equipment manufacturers will either need to develop new equipment to
          handle these needs or modify the existing products to place these
          components.
          <br />
          <br />
          Areas that are gaining attention are chemical/biological detection as
          well as proteomics/genomics. Resources have been allocated for the
          development of products that can detect chemical or biological agents
          in real time.
          <br />
          <br />
          Bio Alloy, one such technology, is based on nanostructured and
          chemically modified silicon. The detection process is based on
          photoluminescence response. The response relies on the changes in the
          surface energy state when the material is excited with low-power blue
          LEDs. Upon binding to the target agents, the recognition element
          linked to the chip surface changes the photoluminescence response to
          green once it is detected. This allows for real time detection.
          <br />
          With biochips moving to the forefront, SMT equipment vendors will need
          to address this market in the same way as they did when 0201-size
          components were first introduced.
          <br />
          <br />
          Surface-mount photonic (SMP) components go through three stages of
          manufacturing and assembly, leveraging processes originally developed
          by the semiconductor industry. These stages are chip production,
          optical assembly and packaging. Leading edge optical components are
          assembled using the low-cost process used to assemble electronic
          devices including lasers and detectors.
        </p>
      </div>
    </div>
  );
}

export default NewsPaper2;
